import { PlusIcon } from "../../../assets/imgs";
import Modal from "../../../components/Modal/Modal";
import { Form, Field, Formik } from "formik";
import FilledButton from "../../../components/FilledButton/FilledButton";
import * as Yup from "yup";
import { useStore } from "../../../store/useStore";
import useModal from "../../../hooks/useModal";
import OutlinedButton from "../../../components/OutlinedButton/OutlinedButton";
import AsyncOutlinedSelect from "../../../components/AsyncOutlinedSelect/AsyncOutlinedSelect";
import { Collaborator } from "../../../store/types/collaborator";
import OutlinedSelect from "../../../components/FormComponents/OutlinedSelect/OutlinedSelect";
import CustomDateTimePicker from "../../../components/FormComponents/CustomDateTimePicker/CustomDateTimePicker";
import CustomDatePicker from "../../../components/FormComponents/CustomDatePicker/CustomDatePicker";
import OutlinedInput from "../../../components/FormComponents/OutlinedInput/OutlinedInput";
import CustomTimeInput from "../../../components/CustomTimeInput/CustomTimeInput";
import { COMPENSATORY_TIME_CATEGORY_OPTIONS, COMPENSATORY_TIME_JOURNEY_MODIFIER_CATEGORY_OPTIONS, COMPENSATORY_TIME_TYPE, COMPENSATORY_TIME_TYPE_OPTIONS, REASONS_BY_CATEGORY } from "../../../utils/constants/constants";
import CollaboratorsSelectTable from "./CollaboratorsSelectTable";
import { formatCPF } from "../../../utils/functions/formatters";
import TagsInput from "../../../components/TagsInput/TagsInput";
import TagsCustomDatePicker from "../../../components/FormComponents/TagsCustomDatePicker/TagsCustomDatePicker";
import { useEffect } from "react";

const CreateCompensatoryTimeModal: React.FC<{}> = () => {
  const createCompensatoryTimeRegister = useStore(
    (state) => state.timeManager.createCompensatoryTimeRegister
  );
  const isCreatingCompensatoryTimeRegister = useStore(
    (state) => state.timeManager.isCreatingCompensatoryTimeRegister
  );
  const getCollaboratorOptions = useStore(
    (state) => state.collaborator.getCollaboratorOptions
  );

  const getCollaborators = useStore(
    (state) => state.collaborator.getCollaborators
  );
  const resetCollaboratorListState = useStore(
    (state) => state.collaborator.resetCollaboratorListState
  );

  useEffect(() => {
    getCollaborators();
    return () => {
      resetCollaboratorListState();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const { closeModal } = useModal();

  const getCollaboratorOptionsCallback = async (inputValue: string) => {
    const options = (await getCollaboratorOptions(inputValue)).map(
      (c: Collaborator) => {
        return { label: c.name, value: c._id };
      }
    );
    return options;
  };

  const user_columns = [
    { key: "name", label: "Nome" },
    {
      key: "cpf",
      label: "CPF",
      render: (item: Collaborator) => formatCPF(item.cpf),
    },
  ];

  return (
    <Modal
      title="Novo registro de banco de horas"
      icon={<PlusIcon width={30} height={30} />}
      className="w-[647px]"
    >
      <Formik
        initialValues={{
          user: {
            label: "",
            value: "",
          },
          users: {} as Record<string, any>,
          days: [],
          type: "",
          category: "",
          amount: "",
          referenceDateStart: "",
          referenceDateEnd: "",
          deadline: "",
          reason: "",
          description: "",
        }}
        validationSchema={Yup.object().shape({
          // user: Yup.object().shape({
          //   label: Yup.string().required("Campo obrigatório"),
          //   value: Yup.string().required("Campo obrigatório"),
          // }),
          type: Yup.string().required("Campo obrigatório"),
          category: Yup.string().when("type", {
            is: (val: string) => val === COMPENSATORY_TIME_TYPE.JUSTIFICATIVE,
            then: (schema) => schema,
            otherwise: (schema) => schema.required("Campo obrigatório"),
          }),
          amount: Yup.string().when("type", {
            is: (val: string) => val === COMPENSATORY_TIME_TYPE.JUSTIFICATIVE || val === COMPENSATORY_TIME_TYPE.JOURNEY_MODIFIER,
            then: (schema) => schema,
            otherwise: (schema) => schema.required("Campo obrigatório"),
          }),
          referenceDateStart: Yup.string().when("type", {
            is: (val: string) => val === COMPENSATORY_TIME_TYPE.JOURNEY_MODIFIER,
            then: (schema) => schema,
            otherwise: (schema) => schema.required("Campo obrigatório"),
          }),
          referenceDateEnd: Yup.string().when("type", {
            is: (val: string) => val === COMPENSATORY_TIME_TYPE.JOURNEY_MODIFIER,
            then: (schema) => schema,
            otherwise: (schema) => schema.required("Campo obrigatório"),
          }),
          deadline: Yup.string().when("type", {
            is: (val: string) => val === COMPENSATORY_TIME_TYPE.JUSTIFICATIVE || val === COMPENSATORY_TIME_TYPE.JOURNEY_MODIFIER,
            then: (schema) => schema,
            otherwise: (schema) => schema.required("Campo obrigatório"),
          }),
          reason: Yup.string().required("Campo obrigatório"),
          description: Yup.string().required("Campo obrigatório")
        })}
        onSubmit={(values) => {
          if((
              values.user.label === '' || 
              values.user.value === ''
            ) && Object.keys(values.users).filter((k: string) => values.users[k]).length === 0) return;
          if(
            values.type === COMPENSATORY_TIME_TYPE.JOURNEY_MODIFIER && 
            values.days.length === 0
          ) return;

          let compensatoryTimeRegister: any = {
            type: values.type,
            manual: {
              description: values.description,
              reason: values.reason,
            }
          };

          if(values.type === COMPENSATORY_TIME_TYPE.JOURNEY_MODIFIER) {
            compensatoryTimeRegister = {
              ...compensatoryTimeRegister,
              category: values.category,
              modifiers: {                
                users: Object.keys(values.users).filter(
                  (k: string) => values.users[k]
                ),
                days: values.days.map((date: string) => {
                  const [ day, month, year ] = date.split("/");
                  const currentTimezoneInHours = new Date().getTimezoneOffset() / 60;
                  return new Date(`${year}-${month}-${day}`).toISOString().replace('Z', currentTimezoneInHours > 0 ? `-${currentTimezoneInHours.toString().padStart(2, '0')}:00` : `+${currentTimezoneInHours.toString().padStart(2, '0')}:00`);
              }),
              }
            };
          } else if (values.type !== COMPENSATORY_TIME_TYPE.JUSTIFICATIVE) {
            const [hours, minutes, seconds] = values.amount.split(":").map(Number);
            const amountInSeconds = hours * 3600 + minutes * 60 + seconds;

            compensatoryTimeRegister = {
              ...compensatoryTimeRegister,
              amount: amountInSeconds,
              category: values.category,
              deadline: values.deadline,
              user: values.user.value,
              reference_date_start: values.referenceDateStart,
              reference_date_end: values.referenceDateEnd,
            };
          } else {
            compensatoryTimeRegister = {
              ...compensatoryTimeRegister,
              user: values.user.value,
              reference_date_start: values.referenceDateStart,
              reference_date_end: values.referenceDateEnd,
            };
          }
          createCompensatoryTimeRegister(compensatoryTimeRegister);
        }}
      >
        {(formik) => {
          return (
            <Form>
              <div className="flex flex-col gap-8 pt-5">
                
                <div className="text-dark-blue font-semibold">Tipo</div>
                
                <div className="flex gap-5">
                  <div className="flex-1">
                    <Field
                      name="type"
                      component={OutlinedSelect}
                      onChange={(e: any) => {
                          formik.resetForm();
                          formik.setFieldValue("type", e.target.value);
                      }}
                      placeholder="Tipo"
                      options={COMPENSATORY_TIME_TYPE_OPTIONS}
                    />
                  </div>
                </div>

                {
                  formik.values.type === COMPENSATORY_TIME_TYPE.CREDIT ||
                  formik.values.type === COMPENSATORY_TIME_TYPE.DEBIT
                ? (
                  <div className="flex flex-col gap-8">
                    <div className="text-dark-blue font-semibold">Dados</div>
                    <div className="flex gap-5">
                      <div className="flex-1">
                        <Field
                          name="user"
                          component={AsyncOutlinedSelect}
                          callback={getCollaboratorOptionsCallback}
                          value={{
                            value: formik.values.user.value,
                            label: formik.values.user.label,
                          }}
                          onChange={(option: any) =>
                            formik.setFieldValue("user", option)
                          }
                          menuPlacement="top"
                          placeholder="Motorista"
                        />
                      </div>
                    </div>
                    <div className="flex gap-5">
                      <div className="flex-1">
                        <Field
                          placeholder="Quantidade"
                          name="amount"
                          onChange={(v: any) => {
                            formik.setFieldValue("amount", v);
                          }}
                          value={formik.values.amount}
                          component={CustomTimeInput}
                        />
                      </div>
                      <div className="flex-1">
                        <Field
                          name="category"
                          component={OutlinedSelect}
                          placeholder="Categoria"
                          options={COMPENSATORY_TIME_CATEGORY_OPTIONS}
                        />
                      </div>
                    </div>
                    <div className="flex gap-5">
                      <div className="flex-1">
                        <Field
                          placeholder="Data de Início"
                          name="referenceDateStart"
                          component={CustomDateTimePicker}
                        />
                      </div>
                      <div className="flex-1">
                        <Field
                          placeholder="Data de Fim"
                          name="referenceDateEnd"
                          component={CustomDateTimePicker}
                        />
                      </div>
                    </div>
                    <div className="flex gap-5">
                      <div className="flex-1">
                        <Field
                          placeholder="Data de Vencimento"
                          name="deadline"
                          component={CustomDatePicker}
                        />
                      </div>
                      <div className="flex-1">
                        <Field
                          name="reason"
                          component={OutlinedSelect}
                          placeholder="Motivo"
                          options={REASONS_BY_CATEGORY?.[formik.values.type as keyof typeof REASONS_BY_CATEGORY] ?? []}
                        />
                      </div>
                    </div>
                    <Field
                      placeholder="Observações"
                      name="description"
                      component={OutlinedInput}
                    />
                  </div>
                ) : null}

                {formik.values.type === COMPENSATORY_TIME_TYPE.JUSTIFICATIVE ? (
                  <div className="flex flex-col gap-8">
                    <div className="text-dark-blue font-semibold">Dados</div>
                    <div className="flex gap-5">
                      <div className="flex-1">
                        <Field
                          name="user"
                          component={AsyncOutlinedSelect}
                          callback={getCollaboratorOptionsCallback}
                          value={{
                            value: formik.values.user.value,
                            label: formik.values.user.label,
                          }}
                          onChange={(option: any) =>
                            formik.setFieldValue("user", option)
                          }
                          menuPlacement="top"
                          placeholder="Motorista"
                        />
                      </div>
                    </div>
                    <div className="flex gap-5">
                      <div className="flex-1">
                        <Field
                          placeholder="Data de Início"
                          name="referenceDateStart"
                          component={CustomDateTimePicker}
                        />
                      </div>
                      <div className="flex-1">
                        <Field
                          placeholder="Data de Fim"
                          name="referenceDateEnd"
                          component={CustomDateTimePicker}
                        />
                      </div>
                    </div>
                    <div className="flex gap-5">
                      <div className="flex-1">
                        <Field
                          name="reason"
                          component={OutlinedSelect}
                          placeholder="Motivo"
                          options={REASONS_BY_CATEGORY?.[formik.values.type as keyof typeof REASONS_BY_CATEGORY] ?? []}
                        />
                      </div>
                    </div>
                    <Field
                      placeholder="Observações"
                      name="description"
                      component={OutlinedInput}
                    />
                  </div>
                ) : null }

                {formik.values.type === COMPENSATORY_TIME_TYPE.JOURNEY_MODIFIER ? (
                  <div className="flex flex-col gap-8">
                    <div className="text-dark-blue font-semibold">Dados</div>
                    <div className="flex gap-5">
                      <div className="flex-1">
                        <Field
                          name="category"
                          component={OutlinedSelect}
                          placeholder="Categoria"
                          options={COMPENSATORY_TIME_JOURNEY_MODIFIER_CATEGORY_OPTIONS}
                        />
                      </div>
                      <div className="flex-1">
                        <Field
                          name="reason"
                          component={OutlinedSelect}
                          placeholder="Motivo"
                          options={REASONS_BY_CATEGORY?.[formik.values.type as keyof typeof REASONS_BY_CATEGORY] ?? []}
                        />
                      </div>
                    </div>
                    <Field
                      placeholder="Observações"
                      name="description"
                      component={OutlinedInput}
                    />
                    <div className="text-dark-blue font-semibold">Dias</div>
                    <div className="flex gap-5">
                      <div className="flex-1">
                        <Field
                          placeholder="Data"
                          name="referenceDateStart"
                          targetFieldName="days"
                          component={TagsCustomDatePicker}
                        />
                      </div>
                      <div className="flex-1"></div>
                    </div>
                    { formik.values.days?.length > 0 ? (
                       <div className="flex gap-5">
                       <div className="flex-1">
                         <div className="text-red text-sm">
                           {(formik.errors?.days as any) && (formik.touched?.days as any)
                             ? (formik.errors?.days as any)
                             : null}
                         </div>
                         <Field name={'days'} component={TagsInput} disabled={true} />
                       </div>
                     </div>
                    ) : null }
                    <div className="text-dark-blue font-semibold">Colaboradores</div>
                    <div className="flex gap-5">
                      <div className="flex-1">
                        <div className="text-red text-sm">
                          {(formik.errors?.users as any) && (formik.touched?.users as any)
                            ? (formik.errors?.users as any)
                            : null}
                        </div>
                        <CollaboratorsSelectTable
                          columns={user_columns}
                          form={formik}
                          fieldName="users"
                        />
                      </div>
                    </div>
                  </div>
                ) : null }

                <div className="flex gap-5 justify-end">
                  <div>
                    <OutlinedButton
                      className="w-[200px]"
                      onClick={() => closeModal()}
                    >
                      Cancelar
                    </OutlinedButton>
                  </div>
                  <div>
                    <FilledButton
                      loading={isCreatingCompensatoryTimeRegister}
                      className="w-[200px]"
                      type="submit"
                    >
                      Cadastrar
                    </FilledButton>
                  </div>
                </div>
              </div>
            </Form>
          );
        }}
      </Formik>
    </Modal>
  );
};

export default CreateCompensatoryTimeModal;
